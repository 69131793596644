import { ComponentType, FC, Fragment, PropsWithChildren, ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { VSpacing } from '@hh.ru/magritte-ui';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import defaultError from 'src/components/Notifications/DefaultError';
import { useNotification } from 'src/components/Notifications/Provider';
import { resumeCommentDeleteMessage } from 'src/components/Notifications/ResumeComments';
import { useSelector } from 'src/hooks/useSelector';
import {
    addBatchCommentsToUser,
    addCommentToUser,
    deleteCommentFromUser,
} from 'src/models/employer/resume/commentsByUser';
import {
    addComment,
    editComment,
    loadComments,
    ResumeComment,
    ResumeCommentsList,
    startFetch,
    Status,
} from 'src/models/employer/resume/resumeComments';
import fetcher from 'src/utils/fetcher';

import Add from 'src/components/ResumeComments/Add';
import { CommentProps } from 'src/components/ResumeComments/Item';
import List from 'src/components/ResumeComments/List';
import { CommentsOwner } from 'src/components/ResumeComments/types';

const COMMENTS_ON_PAGE = 3;

const COMMENTS_URL = '/employer/applicant/json_comment';

declare global {
    interface FetcherGetApi {
        [COMMENTS_URL]: {
            response: ResumeCommentsList;
            // eslint-disable-next-line camelcase
            queryParams: { applicantId: CommentsOwner; print: boolean; offset: number; limit: number };
        };
    }
}

interface ResumeCommentsProps {
    user: CommentsOwner;
    visible?: boolean;
    addFromCard?: boolean;
    noCommentsCallback?: () => void;
    render?: {
        add?: () => ReactNode;
        item?: ComponentType<CommentProps>;
    };
}

const ResumeComments: FC<ResumeCommentsProps & PropsWithChildren> = ({
    visible = true,
    addFromCard,
    noCommentsCallback,
    user,
    render = {},
}) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const commentsByUser = useSelector((store) => store.commentsByUserId?.[user]);
    const resumeCommentsLimit = useSelector(({ resumeCommentsLimit }) => resumeCommentsLimit);
    const comments = useSelector((store) => {
        return {
            items:
                commentsByUser?.items && store.resumeComments.items
                    ? commentsByUser.items.map((id) => store.resumeComments.items[id])
                    : [],
            total: commentsByUser?.total || 0,
            maxOffset: commentsByUser?.maxOffset || 0,
            status: store.resumeComments.status,
        };
    });
    comments.items = comments.items.length ? comments.items : [];

    const print = useSelector((state) => !!state.printVersion.comments);
    const [editId, setEditId] = useState<string | number | null>(addFromCard && !comments.items.length ? 'add' : null);
    const [fetchingIds, setFetchingIds] = useState<number[]>([]);

    const fetch = useCallback(
        (offset: number) => {
            dispatch(startFetch());
            fetcher
                .get(COMMENTS_URL, {
                    params: {
                        applicantId: user,
                        print,
                        offset,
                        limit: resumeCommentsLimit || COMMENTS_ON_PAGE,
                    },
                })
                .then((response) => {
                    if (response.total === 0) {
                        noCommentsCallback?.();
                    }
                    dispatch([
                        loadComments(response),
                        addBatchCommentsToUser({
                            userId: user,
                            commentIds: response.data.map(({ id }) => id),
                            total: response.total,
                            maxOffset: response.maxOffset,
                        }),
                    ]);
                })
                .catch((e) => {
                    defaultRequestErrorHandler(e, addNotification);
                });
        },
        [dispatch, user, print, resumeCommentsLimit, noCommentsCallback, addNotification]
    );

    useEffect(() => {
        if (comments.status === Status.Initial) {
            fetch(0);
        }
    }, [comments, fetch]);

    const add = useCallback(
        (data: ResumeComment) => {
            dispatch([addComment(data), addCommentToUser({ userId: user, commentId: data.id })]);
        },
        [dispatch, user]
    );

    const edit = useCallback(
        (data: ResumeComment) => {
            dispatch(editComment(data));
        },
        [dispatch]
    );

    const commentAction = useCallback(
        (id: number, action: string) => {
            setFetchingIds([...fetchingIds, id]);
            fetcher
                .postFormData(`/employer/applicant/json_comment`, {
                    action,
                    id,
                    applicantId: user,
                })
                .then(() => {
                    setFetchingIds(fetchingIds.filter((fetchingId) => fetchingId !== id));
                    if (action === 'remove') {
                        addNotification(resumeCommentDeleteMessage, {
                            props: {
                                restoreComment: () => commentAction(id, 'restore'),
                            },
                        });
                        dispatch(deleteCommentFromUser({ userId: user, commentId: id }));
                    }
                    if (action === 'restore') {
                        dispatch(addCommentToUser({ userId: user, commentId: id }));
                    }
                })
                .catch(() => {
                    setFetchingIds(fetchingIds.filter((fetchingId) => fetchingId !== id));
                    addNotification(defaultError);
                });
        },
        [fetchingIds, user, addNotification, dispatch]
    );

    const addComponentProps = {
        editId,
        setEditId,
        user,
        hide: comments.status === Status.Fetching && comments.items.length === 0,
        onAdd: add,
        addFromCard,
    };
    const addComponent = render.add ? render.add() : <Add {...addComponentProps} />;

    if (!visible) {
        return null;
    }

    return (
        <Fragment>
            {addFromCard && <VSpacing default={12} />}
            {addComponent}
            {comments.items && (
                <List
                    fetchingIds={fetchingIds}
                    editId={editId}
                    setEditId={setEditId}
                    onEdit={edit}
                    commentAction={commentAction}
                    user={user}
                    comments={comments}
                    fetch={fetch}
                    BodyComponent={render.item}
                />
            )}
        </Fragment>
    );
};

export default ResumeComments;
