import { useCallback, useState } from 'react';

import pfpBuyContactsButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/pfp/pfp_buy_contacts_button_click';
import { Button } from '@hh.ru/magritte-ui';
import BlokoButton, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import MenuItem from 'bloko/blocks/drop/Menu/Item';
import Loading from 'bloko/blocks/loading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FormatMoney from 'src/components/FormatMoney';
import useEmployerBillingInfo from 'src/components/SupernovaMainMenu/EmployerBillingInfoSection/useEmployerBillingInfo';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import { usePfpInfo } from 'src/hooks/usePfpInfo';
import { useSelector } from 'src/hooks/useSelector';
import { BoughtTopicsError } from 'src/models/boughtTopics';
import { ResumeCardData } from 'src/models/resumeCard';
import { MIDDLE_DOT, NON_BREAKING_SPACE } from 'src/utils/constants/symbols';
import fetcher from 'src/utils/fetcher';

import Error from 'src/components/PfpBillingButton/Error';

const TrlKeys = {
    text: 'pfpBillingButton.text',
};

const BUY_PFP_CONTACTS_URL = '/shards/buy_pfp_contacts';
const GET_RESUME_URL = '/shards/resume';

type SucceedTopic = {
    status: 'SUCCESS';
};

type FailedTopic = {
    status: 'FAIL';
    errorDetails: {
        code: BoughtTopicsError;
        message: string;
    };
};

const NoErrorFailedTopicCodes: BoughtTopicsError[] = ['CONTACTS_ALREADY_VISIBLE', 'CONTACTS_ALREADY_BOUGHT'];

declare global {
    interface FetcherPostApi {
        [BUY_PFP_CONTACTS_URL]: {
            queryParams: void;
            body: {
                resumeId: string;
                applicantId: string;
                topicId?: string;
            };
            response: {
                byTopicIds: Record<string, SucceedTopic | FailedTopic>;
            };
        };
    }

    interface FetcherGetApi {
        [GET_RESUME_URL]: {
            queryParams: { id: string; topicId?: string; vacancyId?: string; resumeHash: string };
            response: ResumeCardData;
        };
    }
}

interface PfpBillingButtonProps {
    onContactsBought: (resume: ResumeCardData) => void;
    resumeHash: string;
    topicId?: string;
    resumeId: string;
    applicantId: string;
    isAccent?: boolean;
    type?: 'button' | 'link';
    isMobile?: boolean;
}

const PfpBillingButton: TranslatedComponent<PfpBillingButtonProps> = ({
    trls,
    onContactsBought,
    resumeId,
    resumeHash,
    applicantId,
    topicId,
    isAccent = false,
    type = 'button',
    isMobile = false,
}) => {
    const { fetchEmployerBillingInfo } = useEmployerBillingInfo();
    const [isLoading, setIsLoading] = useState(false);
    const { vacancyId } = useSelector((state) => state.router.location.query);
    const [error, setError] = useState<BoughtTopicsError | null>(null);
    const { pfpDetails: { currency, relevantActionPrice: amount } = {} } = usePfpInfo();
    const isMagritte = useMagritte();

    const onCloseError = useCallback(() => {
        setError(() => null);
    }, []);

    const handleBuyPfpContacts = useCallback(async () => {
        if (isLoading) {
            return;
        }
        pfpBuyContactsButtonClick({ resumeId, topicId, vacancyId });
        try {
            setIsLoading(true);
            const body: FetcherPostApi[typeof BUY_PFP_CONTACTS_URL]['body'] = {
                resumeId,
                applicantId,
                ...(topicId && { topicId }),
            };
            const result = await fetcher.post(BUY_PFP_CONTACTS_URL, body);
            const failedTopic = Object.values(result.data.byTopicIds).find(
                (topicInfo) =>
                    topicInfo.status === 'FAIL' && !NoErrorFailedTopicCodes.includes(topicInfo.errorDetails.code)
            );
            setIsLoading(false);
            if (!failedTopic) {
                const resume = await fetcher.get(GET_RESUME_URL, {
                    params: {
                        id: resumeId,
                        resumeHash,
                        topicId,
                        vacancyId,
                    },
                });
                void fetchEmployerBillingInfo();
                onContactsBought(resume);
            } else if (failedTopic.status === 'FAIL') {
                setError(failedTopic.errorDetails.code);
            } else {
                setError('UNHANDLED_ERROR');
            }
        } catch (e) {
            setError('UNHANDLED_ERROR');
            setIsLoading(false);
        }
    }, [isLoading, resumeId, topicId, vacancyId, applicantId, resumeHash, fetchEmployerBillingInfo, onContactsBought]);

    const text = (
        <>
            {trls[TrlKeys.text]}
            {NON_BREAKING_SPACE}
            {MIDDLE_DOT}
            {currency && amount && (
                <>
                    {NON_BREAKING_SPACE}
                    <FormatMoney currency={currency}>{amount}</FormatMoney>
                </>
            )}
        </>
    );

    let renderItem;

    if (type === 'button') {
        if (isMagritte) {
            renderItem = (
                <Button
                    mode="secondary"
                    size="small"
                    onClick={handleBuyPfpContacts}
                    loading={isLoading}
                    stretched={isMobile}
                >
                    {text}
                </Button>
            );
        } else {
            renderItem = (
                <BlokoButton
                    appearance={isAccent ? ButtonAppearance.Filled : ButtonAppearance.Outlined}
                    kind={isAccent ? ButtonKind.Primary : undefined}
                    onClick={handleBuyPfpContacts}
                    loading={isLoading ? <Loading /> : null}
                    stretched={isMobile}
                >
                    {text}
                </BlokoButton>
            );
        }
    } else {
        renderItem = <MenuItem onClick={handleBuyPfpContacts}>{text}</MenuItem>;
    }

    return (
        <>
            {renderItem}
            {error && (
                <Error
                    resumeId={resumeId}
                    error={error}
                    onClose={onCloseError}
                    topicId={topicId}
                    vacancyId={vacancyId}
                />
            )}
        </>
    );
};

export default translation(PfpBillingButton);
