import {
    MouseEvent,
    MouseEventHandler,
    MutableRefObject,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { OK } from 'http-status-codes';

import Analytics from '@hh.ru/analytics-js';
import { TooltipHover, HSpacing } from '@hh.ru/magritte-ui';
import { ExclamationTriangleFilledSize16 } from '@hh.ru/magritte-ui/icon';
import { TelegramSolidSize16, ViberSolidSize16, WhatsappSolidSize16 } from '@hh.ru/magritte-ui/service';
import HoverTip, { TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';
import FormSpacer from 'bloko/blocks/form/FormSpacer';
import {
    ExclamationScaleSmallShapeTriangle,
    IconColor,
    WhatsappScaleSmallAppearanceDefault,
    WhatsappScaleSmallAppearanceOutlined,
    ViberScaleSmallAppearanceDefault,
    ViberScaleSmallAppearanceOutlined,
    TelegramScaleSmallAppearanceDefault,
    TelegramScaleSmallAppearanceOutlined,
} from 'bloko/blocks/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import Notices from 'Modules/Notices';
import { DeepLink } from 'Utils/DeepLink';
import { getMessengerLinks } from 'Utils/DeepLinkHelper';
import { useNotification } from 'src/components/Notifications/Provider';
import getContactPhones from 'src/components/ResumeView/getContactPhones';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import type { Phone } from 'src/models/resumeCard';

import styles from './deepLinks.less';

const TrlKeys = {
    search: 'mass_sending.usergroupedit.check',
    scope: 'vacancies.title.in',
    whatsapp: 'rb.personalsites.whatsapp',
    viber: 'rb.personalsites.viber',
    telegram: 'rb.personalsites.telegram',
    tryLink: 'rb.personalsites.deeplink.try',
    warning: 'deeplink.warning',
};

interface ResumePhoneDeepLinksProps {
    resumeId: string;
    created: number;
    resumeHash: string;
    simHash?: string;
    visited?: boolean;
    fromResumeView?: boolean;
    phone: Phone;
    isResumeCard?: boolean;
    markAsRead?: () => void;
}

enum Messenger {
    Telegram = 'telegram',
    Viber = 'viber',
    Whatsapp = 'whatsapp',
}

const MessengerIconMap = {
    [Messenger.Telegram]: TelegramSolidSize16,
    [Messenger.Viber]: ViberSolidSize16,
    [Messenger.Whatsapp]: WhatsappSolidSize16,
};

const Features = {
    warningMessengers: 'warning_messengers',
};

const ResumePhoneDeepLinks: TranslatedComponent<ResumePhoneDeepLinksProps> = ({
    trls,
    resumeId,
    created,
    simHash,
    resumeHash,
    phone,
    visited,
    fromResumeView,
    isResumeCard = false,
    markAsRead,
}) => {
    const isMagritte = useMagritte();

    const dispatch = useDispatch();
    const displayType = useSelector(({ displayType }) => displayType);
    const vacancyId = useSelector(({ router }) => router.location.query?.vacancyId);
    const infoTipName = useSelector(({ infoTip }) => infoTip.name);
    const warningMessengers = (useSelector(({ features }) => features[Features.warningMessengers]) as string) || '';
    const showDropDown = infoTipName === 'whatsAppWarning' && fromResumeView;
    const { addNotification } = useNotification();

    const activatorRefs = useRef<Record<number, MutableRefObject<HTMLSpanElement | null>>>({});

    const resumePhone = phone.raw;
    const links = useMemo(
        () => getMessengerLinks({ phone: resumePhone || '', displayType }),
        [displayType, resumePhone]
    );
    const [messenger, setMessenger] = useState<Messenger | null>(null);
    const [isAsyncDeepLink, setAsyncDeepLink] = useState(false);
    const handleDeepLink = useCallback(
        (messengerType: Messenger) => {
            Analytics.sendHHEvent('resumeShowContacts', {
                goal: messengerType.toUpperCase(),
                resumeId,
                vacancyId,
            });
            const deepLink = new DeepLink({
                fallbackUrl: links[messengerType].shallow,
                androidUrl: links[messengerType].android,
                iosUrl: links[messengerType].ios,
            });
            deepLink.open(links[messengerType].deep);
        },
        [links, resumeId, vacancyId]
    );
    const handlePhoneCheck = useCallback(
        (messengerType: Messenger, event: MouseEvent) => {
            markAsRead?.();

            if (resumePhone) {
                handleDeepLink(messengerType);
                return;
            }

            void getContactPhones(
                { created, resumeHash, simHash, event, goal: `Contacts_${messengerType}` },
                addNotification
            )(dispatch).then(({ status }) => {
                if (status === OK) {
                    setMessenger(messengerType);
                    setAsyncDeepLink(true);
                }
            });
        },
        [markAsRead, resumePhone, created, resumeHash, simHash, addNotification, dispatch, handleDeepLink]
    );

    const handleViberClick: MouseEventHandler = useCallback(
        (event) => handlePhoneCheck(Messenger.Viber, event),
        [handlePhoneCheck]
    );
    const handleTelegramClick: MouseEventHandler = useCallback(
        (event) => handlePhoneCheck(Messenger.Telegram, event),
        [handlePhoneCheck]
    );
    const handleWhatsappClick: MouseEventHandler = useCallback(
        (event) => handlePhoneCheck(Messenger.Whatsapp, event),
        [handlePhoneCheck]
    );

    useEffect(() => {
        const handleClearLinkData = () => {
            setMessenger(null);
        };
        window.addEventListener('blur', handleClearLinkData);

        return () => window.removeEventListener('blur', handleClearLinkData);
    }, []);

    useEffect(() => {
        if (resumePhone && isAsyncDeepLink && messenger) {
            handleDeepLink(messenger);
            setAsyncDeepLink(false);
        }
    }, [isAsyncDeepLink, resumePhone, messenger, handleDeepLink]);

    const deepLinks = [
        {
            name: Messenger.Whatsapp,
            icon: { default: WhatsappScaleSmallAppearanceDefault, outlined: WhatsappScaleSmallAppearanceOutlined },
            handler: handleWhatsappClick,
        },
        {
            name: Messenger.Viber,
            icon: { default: ViberScaleSmallAppearanceDefault, outlined: ViberScaleSmallAppearanceOutlined },
            handler: handleViberClick,
        },
        {
            name: Messenger.Telegram,
            icon: { default: TelegramScaleSmallAppearanceDefault, outlined: TelegramScaleSmallAppearanceOutlined },
            handler: handleTelegramClick,
        },
    ] as const;

    let initalColor = visited ? IconColor.Gray50 : IconColor.Gray80;
    if (isResumeCard) {
        initalColor = IconColor.Gray30;
    }
    const deepLinksContent = (
        <>
            {deepLinks.map(({ name, icon, handler }, index) => {
                if (!isMagritte) {
                    const LinkIcon = icon[Messenger.Telegram || isResumeCard ? 'default' : 'outlined'];
                    return (
                        <HoverTip
                            key={name}
                            placement={TipPlacement.Bottom}
                            host={process.env.LUX_SERVER ? null : document.body}
                            render={() =>
                                warningMessengers.includes(name)
                                    ? trls[TrlKeys.warning]
                                    : format(trls[TrlKeys.tryLink], {
                                          '{0}': trls[TrlKeys[name]],
                                      })
                            }
                            show={name === Messenger.Whatsapp && showDropDown}
                            onHover={() => {
                                if (name === Messenger.Whatsapp && showDropDown) {
                                    infoTipName && Notices.markAsViewed(infoTipName);
                                }
                            }}
                        >
                            <span
                                className={classnames(styles.deepLink, {
                                    [styles.deepLinkTelegram]: name === Messenger.Telegram && isResumeCard,
                                    [styles.deepLinkViber]: name === Messenger.Viber,
                                    [styles.deepLinkWhatsapp]: name === Messenger.Whatsapp,
                                    [`resume-contact-${name}`]: true,
                                    [`noprint`]: isResumeCard,
                                })}
                                onClick={handler}
                                // Избегаем выделения карточки отклика
                                data-is-avoid-click={true}
                            >
                                <LinkIcon initial={initalColor} highlighted={IconColor.Gray60} />
                                {warningMessengers.includes(name) && (
                                    <>
                                        {isResumeCard && <HSpacing default={4} />}
                                        <ExclamationScaleSmallShapeTriangle initial={IconColor.Red60} />
                                    </>
                                )}
                                {!isResumeCard ? (
                                    <FormSpacer>{trls[TrlKeys[name]]}</FormSpacer>
                                ) : (
                                    <>
                                        <HSpacing default={4} />
                                        {trls[TrlKeys[name]]}
                                    </>
                                )}
                            </span>
                        </HoverTip>
                    );
                }

                const MessengerIcon = MessengerIconMap[name];

                const deepLink = () => (
                    <span
                        className={classnames(styles.deepLink, `resume-contact-${name}`, { noprint: isResumeCard })}
                        onClick={handler}
                        // Избегаем выделения карточки отклика
                        data-is-avoid-click={true}
                        ref={(element) => {
                            activatorRefs.current[index] = { current: element };
                        }}
                    >
                        <MessengerIcon initial="tertiary" />
                        {warningMessengers.includes(name) && (
                            <>
                                {isResumeCard && <HSpacing default={4} />}
                                <ExclamationTriangleFilledSize16 initial="negative" />
                            </>
                        )}
                        <HSpacing default={!isResumeCard ? 8 : 4} gteM={4} />
                        {trls[TrlKeys[name]]}
                    </span>
                );

                if (name === Messenger.Whatsapp && showDropDown) {
                    return (
                        <>
                            {deepLink()}
                            <TooltipHover key={name} placement="top-center" activatorRef={activatorRefs.current[index]}>
                                {warningMessengers.includes(name)
                                    ? trls[TrlKeys.warning]
                                    : format(trls[TrlKeys.tryLink], {
                                          '{0}': trls[TrlKeys[name]],
                                      })}
                            </TooltipHover>
                        </>
                    );
                }

                return deepLink();
            })}
        </>
    );

    if (isResumeCard) {
        return deepLinksContent;
    }

    return <span className={classnames(styles.deepLinks, 'noprint')}>{deepLinksContent}</span>;
};

export default memo(translation(ResumePhoneDeepLinks));
