import { ComponentType, FC, Fragment, memo, ReactNode, useCallback } from 'react';

import Text, { TextImportance } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatNewLine } from 'bloko/common/trl';

import formatDate from 'Modules/formatDate';
import { useNotification } from 'src/components/Notifications/Provider';
import { resumeCommentEditMessage } from 'src/components/Notifications/ResumeComments';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';
import { ResumeComment } from 'src/models/employer/resume/resumeComments';

import Controls from 'src/components/ResumeComments/Controls';
import Form, { CommentsFormProps } from 'src/components/ResumeComments/Form';
import { CommentsOwner } from 'src/components/ResumeComments/types';

const MAX_PREVIEW_LENGTH = 140;

const TrlKeys = {
    visibleToMe: 'resume.comments.visibleToMe',
};

export interface CommentFormEditProps {
    renderBodyField: (props?: { 'data-qa'?: string; rows?: number }) => ReactNode;
    renderSubmit: (props?: { 'data-qa'?: string }) => ReactNode;
    renderCancel: () => ReactNode;
    renderVisibilityField: () => ReactNode;
}

export interface CommentProps {
    body: JSX.Element | string;
    renderControls: () => ReactNode;
    author: string;
    date: number;
    renderForm: (value: { renderWrapper: FC<CommentFormEditProps> }) => ReactNode;
    expandComment: () => void;
    isEditing?: boolean;
}

function truncateBody(body: string) {
    const chunks = body.split(' ');
    let truncatedBody = '';
    const chunkIndex = chunks.findIndex((item) => {
        truncatedBody += `${item} `;
        return truncatedBody.length >= MAX_PREVIEW_LENGTH;
    });
    if (chunkIndex !== -1) {
        return `${chunks.slice(0, chunkIndex).join(' ')}...`;
    }
    return body;
}

interface ItemProps {
    comment: ResumeComment;
    timeDiff?: number;
    user: CommentsOwner;
    onEdit: (data: ResumeComment) => void;
    editId: string | number | null;
    setEditId: (value: number | null) => void;
    commentAction: (value: number, type: string) => void;
    isFetching: boolean;
    BodyComponent?: ComponentType<CommentProps>;
}

const Item: TranslatedComponent<ItemProps> = ({
    trls,
    comment,
    timeDiff,
    user,
    onEdit,
    editId,
    setEditId,
    commentAction,
    isFetching,
    BodyComponent,
}) => {
    const body = truncateBody(comment.body);
    const isPrintVersion = useSelector((state) => state.printVersion.isPrintVersion) || false;
    const [isCommentExpanded, expandComment] = useOnOffState(isPrintVersion);
    const toggleEdit = useCallback(() => setEditId(comment.id), [setEditId, comment.id]);
    const toggleRemove = useCallback(() => commentAction(comment.id, 'remove'), [commentAction, comment.id]);
    const { addNotification } = useNotification();

    const bodyProp = formatNewLine(isCommentExpanded ? comment.body : body);
    const authorProp = comment.userName;
    const dateProp = comment.creationTime + (timeDiff || 0) * 1000;

    const renderControls = () => (
        <Controls
            isFetching={isFetching}
            editable={comment.editable}
            onToggleEdit={toggleEdit}
            onToggleRemove={toggleRemove}
        />
    );
    const renderForm = (props?: Partial<CommentsFormProps>) => {
        if (editId !== comment.id) {
            return null;
        }

        return (
            <Form
                {...props}
                onSubmitSuccessful={(data) => {
                    onEdit(data);
                    setEditId(null);
                    addNotification(resumeCommentEditMessage, { props: { message: data.body } });
                }}
                onCancel={() => setEditId(null)}
                user={user}
                id={comment.id}
                action="edit"
                accessType={comment.accessType}
                body={comment.body}
            />
        );
    };

    return (
        <Fragment key={comment.body}>
            {BodyComponent && (
                <BodyComponent
                    body={bodyProp}
                    author={authorProp}
                    date={dateProp}
                    renderControls={renderControls}
                    renderForm={renderForm}
                    expandComment={expandComment}
                    isEditing={editId === comment.id}
                />
            )}
            {!BodyComponent && editId === comment.id && (
                <div className="resume-sidebar-item" data-qa="resume-comment-item">
                    {renderForm()}
                </div>
            )}
            {!BodyComponent && editId !== comment.id && (
                <div className="resume-sidebar-item" data-qa="resume-comment-item">
                    <div className="resume-sidebar-item__text-wrapper resume-sidebar-item__text-wrapper_full">
                        <span className="resume-sidebar-item__text" data-qa="comment__text" onClick={expandComment}>
                            {bodyProp}
                        </span>
                    </div>
                    <div className="resume-sidebar-item__info">
                        <Text importance={TextImportance.Tertiary}>
                            <span data-qa="comment__author">{authorProp}</span>
                            {', '}
                            <span data-qa="comment__date" suppressHydrationWarning>
                                {formatDate(dateProp, 'dd.MM.yy')}
                            </span>
                        </Text>
                    </div>
                    {comment.accessType === 'OWNER' && (
                        <div className="resume-sidebar-item__info" data-qa="comment__visibility">
                            <Text importance={TextImportance.Tertiary}>{trls[TrlKeys.visibleToMe]}</Text>
                        </div>
                    )}
                    {renderControls()}
                </div>
            )}
        </Fragment>
    );
};

export default memo(translation(Item));
